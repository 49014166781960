import axios from 'axios';
import UseToken from "../auth/UseToken";

export function AxiosPost(url, type, params, object, enqueueSnackbar) {
    const {currentToken, setToken} = UseToken();

    if (!url || !type) {
        enqueueSnackbar('Не переданы параметры запроса', { variant: 'error' });
        return Promise.reject(new Error('Не переданы параметры запроса'));
    }

    const reqHeaders = {
        token: currentToken, // Установка заголовка "token" с заданным значением
    };
    return axios
        .post(url, object, { params: params, headers: reqHeaders })
        .then((response) => {
            const responseData = response.data;
            enqueueSnackbar('Ожидаем ответа', { variant: 'info' });
            return responseData;
        })
        .catch((error) => {
            if(error.response !== undefined)
                enqueueSnackbar(error.response.data.error, { variant: 'error' });
            else
                enqueueSnackbar(error.message, { variant: 'error' });
            return Promise.reject(error);
        });
}

export const AxiosPostWithoutState = async (url, params, object, currentToken) => {
    try {
        const reqHeaders = {
            token: currentToken, // Установка заголовка "token" с заданным значением
        };
        const response = await axios.post(url, object, { params: params, headers: reqHeaders })
        return {
            loaded: true,
            error: null,
            data: response.data,
        }
    } catch (error) {
        return {
            loaded: true,
            error: error.response ? error.response.data.message : error.message,
            data: null
        }
    }
};

export const AxiosPutWithoutState = async (url, params, object, currentToken) => {
    try {
        const reqHeaders = {
            token: currentToken, // Установка заголовка "token" с заданным значением
        };
        const response = await axios.put(url, object, { params: params, headers: reqHeaders })
        return {
            loaded: true,
            error: null,
            data: response.data,
        }
    } catch (error) {
        return {
            loaded: true,
            error: error.response ? error.response.data.message : error.message,
            data: null
        }
    }
};

export const AxiosPostFile = async (url, formData, currentToken) => {
    try {
        // debugger;
        const reqHeaders = {
            token: currentToken,
            'Content-Type': 'multipart/form-data',
        };
        const response = await axios.post(url, formData, { headers: reqHeaders });
        return {
            loaded: true,
            error: null,
            data: response.data,
        };
    } catch (error) {
        return {
            loaded: true,
            error: error.response ? error.response.data.message : error.message,
            data: null
        };
    }
};

