import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import SamplePage from "../views/sample-page/SamplePage";
import JobLauncherView from "../views/operational_task/StatisticRefreshView";
import FileManagerView from "../views/operational_task/FileManagerView";
import BillingManagerView from "../views/operational_task/BillingManagerView";
import AdmitadView from "../views/operational_task/AdmitadView";
import FrameDataGenerationView from "../views/operational_task/FrameDataGenerationView";
import FileOperations from "../views/operational_task/FileOperations";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));



const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/statistic-operations" /> },
      {
        path: '/statistic-operations',
        exact: true,
        element:
            <JobLauncherView group={"ANALYTICS"}/>
      },
      {
        path: '/frame-operations',
        exact: true,
        element: <JobLauncherView group={"FRAME-ACTIONS"}/>
      },
      {
        path: '/file-manager',
        exact: true,
        element: <FileManagerView/>
      },
      {
        path: '/billing-manager',
        exact: true,
        element: <BillingManagerView/>
      },
      {
        path: '/mart-manager',
        exact: true,
        element: <JobLauncherView group={"MART"}/>
      },
      {
        path: '/data-operations',
        exact: true,
        element: <JobLauncherView group={"DATA-OPERATIONS"}/>
      },
      {
        path: '/admitad',
        exact: true,
        element: <AdmitadView/>
      },
      {
        path: '/file-operations',
        exact: true,
        element: <FileOperations/>
      },
      {
        path: '/frame-data-generation',
        exact: true,
        element: <FrameDataGenerationView/>
      },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <SamplePage/> },
      { path: '/auth/login', element: <Login/> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
