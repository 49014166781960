import React, {useEffect, useMemo, useState} from 'react';
import {
    Box,
    Button, Chip, Collapse, Dialog, DialogContent, DialogTitle,
    Grid, IconButton,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField, Typography
} from '@mui/material';
import PageContainer from '../../components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import TypeSwitchBar from "../../layouts/full/TypeSwitchBar/TypeSwitchBar";
import {IconLayoutDashboard, IconSettings} from "@tabler/icons";
import {useSnackbar} from "notistack";
import {AxiosGet, AxiosGetWithoutState} from "../../axios/AxiosGet";
import {
    billingServiceApiUrl,
    frameDataApiUrl, jobServiceApiUrl, processServiceApiUrl
} from "../../schema/Environment";

import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "@mui/material/styles";
import UseToken from "../../auth/UseToken";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {addJobList, addProcessInfoByList, selectJobByProcessId} from "./reducers/jobsSlice";
import {JobContainer} from "./JobsTable";
import axios from "axios";
import {AxiosPostFile, AxiosPostWithoutState, AxiosPutWithoutState} from "../../axios/AxiosPost";
import {StyledTableHeaderCell, StyledTableCell} from "../../components/table/StyledTableComponents";
import {addBillingInfoList} from "./reducers/billingSlice";
import JobLauncherBox from "./JobLauncherBox";
import {Banks, getColorBankByKeyword, getNameBankByKeyword} from "./reducers/bankFilterSlice";
import {fetchPriorityFiles,  selectTransformedFilesAsParams} from "./reducers/priorityFileSlice";
import {
    addVersion,
    fetchFrameDataVersion,
    getColorGenerationStatusesByKeyword,
    translateGenerationStatus
} from "./reducers/frameDataVersionSlice";
import {IconBuildingBank} from "@tabler/icons-react";
import {translateFileStatus} from "./reducers/fileSlice";
import {getColorFileStatusByKeyword} from "./FileManagerView";


const customButtonStyle = {
    height: '30px', // Настройте высоту по необходимости
    // display: 'flex',
    // alignItems: 'center', // Выровнять текст по центру по вертикали
    padding: '0 14px', // Настройте отступы по необходимости
};

const customTextFieldStyle = {
    '& .MuiInputBase-root': {
        height: '30px', // Настройте высоту по необходимости
        display: 'flex',
        alignItems: 'center', // Выровнять текст по центру по вертикали
    },
    '& .MuiOutlinedInput-input': {
        padding: '0 14px', // Настройте отступы по необходимости
    },
    '& .MuiInputLabel-root': {
        top: '50%',
        left: '14px', // Отступ от левого края
        transform: 'translateY(-50%)', // Выровнять подсказку по центру по вертикали
        transition: 'all 0.2s ease', // Добавить анимацию для плавного перемещения
    },
    '& .MuiInputLabel-shrink': {
        top: 0,
        transform: 'translateY(-100%)', // Переместить подсказку наверх при вводе текста
        backgroundColor: 'inherit', // Цвет фона для перекрытия линии
        padding: '0 4px', // Отступы для фона
    },
};

const banks = [
    {
        id: "gpb",
        title: 'Газпром',
        icon: IconLayoutDashboard
    },
    {
        id: "cup",
        title: 'Цупис',
        icon: IconLayoutDashboard
    },
    {
        id: "mts",
        title: 'МТС',
        icon: IconLayoutDashboard
    },
    {
        id: "otp",
        title: 'ОТП Банк',
        icon: IconLayoutDashboard
    },
];

const findUrlById = (id) => {
    const bank = banks.find(bank => bank.id === id);
    return bank ? bank.url : null;
};


export const GenerationStatuses = [
    { name: "Создана", id: "CREATED", color: 'rgb(196,188,27)', count: 10 },
    { name: "Генерируется", id: "GENERATION", color: 'rgb(0, 0, 255)', count: 20 },
    { name: "Завершена", id: "DONE", color: 'rgb(51,135,0)', count: 30 },
    { name: "Ошибка", id: "ERROR", color: 'rgb(163,0,0)', count: 40 }
];

const getStatusNameById = (id) => {
    const status = GenerationStatuses.find(status => status.id === id);
    return status ? status.name : null;
};


function FrameDataGenerationView () {
    const [selectedBank, setSelectedBank] = useState(null);

    const handleSelectBank = (id) => {
        setSelectedBank(id);
    };

    let banksList = Banks.sort((a, b) => a.name.localeCompare(b.name));
    let bankItems = Array.from(banksList, (item) => ({
        id: item.id,
        title: item.name,
        icon: () => <IconBuildingBank stroke={2} color={item.id ? getColorBankByKeyword(item.id) : 'grey'}/>
    }));


    return (
        <>
            <DashboardCard title="Внимание">
                <Box sx={{marginBottom: "1rem"}}>
                    <Typography variant="uptitleBold2" gutterBottom>
                        Данная страница находится в разработке
                    </Typography>
                </Box>
            </DashboardCard>
            <UploadPriorityFile/>

            <CreateFrameGenerationJob/>

            <PageContainer title="Sample Page" description="this is Sample page">
                <TypeSwitchBar title={'Фильтр. Банк'} items={bankItems} valueSetter={handleSelectBank} selectedId={selectedBank} gridSize={"SMALL"}/>
            </PageContainer>

            <GeneratedDataView selectedBank={selectedBank}/>

        </>
    );
}


function CreateFrameGenerationJob(){
    const { enqueueSnackbar } = useSnackbar();
    const {currentToken, setToken} = UseToken();
    const dispatch = useDispatch();
    const transformedFiles = useSelector(selectTransformedFilesAsParams);
    // debugger;
    const banksParams = Banks.map(bank => ({
        key: bank.id,
        value: bank.name
    }));
    const FrameGenerationJob = {
        id: 0,
        description: "Введите параметры для запуска генерации файлов для фреймов",
        jobParams: [
            {
                id: 1,
                name: "bank",
                description: "Банк",
                jobParamType: "STRING_FROM_VALUE_LIST",
                required: true,
                jobParamValues: banksParams
            },
            {
                id: 2,
                name: "generatedOnDate",
                description: "Генерация на дату",
                jobParamType: "DATE",
                required: true,
                jobParamValues: [
                ]
            },
            {
                id: 3,
                name: "rtmmIds",
                description: "Номера кампания",
                jobParamType: "STRING",
                required: false,
                jobParamValues: [
                ]
            },
            {
                id: 4,
                name: "operationMode",
                description: "Тип генерации",
                jobParamType: "STRING_FROM_VALUE_LIST",
                required: true,
                jobParamValues: [
                    { value: "Все файлы", key: "all" },
                    { value: "Операции", key: "operations" },
                    { value: "Медиа файлы", key: "media" }
                ]
            },
            {
                id: 5,
                name: "description",
                description: "Описание генерации для отчета",
                jobParamType: "STRING",
                required: true,
                jobParamValues: []
            },
            {
                id: 6,
                name: "priorityFileId",
                description: "Файл приоритетов",
                jobParamType: "STRING_FROM_VALUE_LIST",
                required: true,
                jobParamValues: transformedFiles.sort((a, b) => b.key - a.key),
                paramsSorted: true
            }

        ]
    };

    const handleClick = (jobData) => {
        const launchParamMap = {};
        // debugger;
        jobData.jobParams.filter(item => item.value !== null && item.value !== "null" && item.value !== undefined && item.value !== "undefined" ).forEach(obj => {
            launchParamMap[obj.name] = obj.value;
        });

        AxiosPostWithoutState(frameDataApiUrl + "/generate",null, launchParamMap,  currentToken).then(({loaded, error, data}) => {

            if(error){
                enqueueSnackbar(error, {variant: "error"});
            }
            else {
                enqueueSnackbar("Задача создана", {variant: "success"});
                dispatch(addVersion(data));
            }
        }).catch(error => {
            enqueueSnackbar("Непредвиденная ошибка при запуске генерации", {variant: "error"});
        });
    }
    return(
        <JobLauncherBox job={FrameGenerationJob} customHandleClick={handleClick}/>
    )
}



function UploadPriorityFile() {
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const {currentToken, setToken} = UseToken();

    const dispatch = useDispatch();
    const { files, status, error } = useSelector((state) => state.priorityFiles);

    useEffect(() => {
        dispatch(fetchPriorityFiles({ frameDataApiUrl, currentToken }));
    }, [dispatch, frameDataApiUrl, currentToken]);


    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleFileUpload = async () => {
        if (!file) {
            alert('Пожалуйста, выберите файл для загрузки.');
            return;
        }

        if (!description) {
            alert('Пожалуйста, введите описание.');
            return;
        }

        const formData = new FormData();
        formData.append('multipartFile', file);
        formData.append('description', description);

        try {

            const { loaded, error, data } = await AxiosPostFile(frameDataApiUrl + '/priority_file', formData, currentToken);

            // debugger;
            if (error) {
                enqueueSnackbar(error, { variant: 'error' });
            } else {
                enqueueSnackbar('Файл успешно загружен', { variant: 'success' });
            }
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Непредвиденная ошибка при загрузке файла', { variant: 'error' });
        }
    };

    const handleSetActive = async (id) => {

        AxiosPutWithoutState(frameDataApiUrl + '/priority_file/active/' + id,null, null,  currentToken).then(({loaded, error, data}) => {
            if(error){
                enqueueSnackbar(error, {variant: "error"});
            }
            else {
                enqueueSnackbar("Сохранено", {variant: "success"});
                dispatch(fetchPriorityFiles({ frameDataApiUrl, currentToken }));
            }
        }).catch(error => {
            console.log(error);
            enqueueSnackbar("Непредвиденная ошибка", {variant: "error"});
        });
    };

    return (
        <DashboardCard>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h6">Загрузить приоритеты оферов</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box>
                        <TextField
                            label="Описание"
                            variant="outlined"
                            value={description}
                            onChange={handleDescriptionChange}
                            fullWidth
                            sx={{ ...customTextFieldStyle, marginBottom: '10px' }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Box>
                        <input
                            accept=".txt,.csv"
                            style={{ display: 'none' }}
                            id="upload-file"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="upload-file">
                            <Button variant="contained" component="span" sx={customButtonStyle}>
                                Выберите файл
                            </Button>
                        </label>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFileUpload}
                            disabled={!file}
                            style={{ marginLeft: '10px' }}
                            sx={customButtonStyle}
                        >
                            Загрузить
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell>ID</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Описание</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Дата создания</StyledTableHeaderCell>
                                    {/*<StyledTableHeaderCell>Активный</StyledTableHeaderCell>*/}
                                    {/*<StyledTableHeaderCell>Действие</StyledTableHeaderCell>*/}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files.map((file) => (
                                    <TableRow key={file.id}>
                                        <StyledTableCell>{file.id}</StyledTableCell>
                                        <StyledTableCell>{file.description}</StyledTableCell>
                                        <StyledTableCell>{new Date(file.createdAt).toLocaleString()}</StyledTableCell>
                                        {/*<StyledTableCell>{file.isActive ? 'Да' : 'Нет'}</StyledTableCell>*/}
                                        {/*<StyledTableCell>*/}
                                        {/*    <Button*/}
                                        {/*        variant="contained"*/}
                                        {/*        color="primary"*/}
                                        {/*        onClick={() => handleSetActive(file.id)}*/}
                                        {/*        disabled={file.isActive}*/}
                                        {/*    >*/}
                                        {/*        Сделать активным*/}
                                        {/*    </Button>*/}

                                        {/*</StyledTableCell>*/}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </DashboardCard>
    );
}


function GeneratedDataView({selectedBank}) {
    const theme = useTheme();
    const url = frameDataApiUrl + "/version";
    const { enqueueSnackbar } = useSnackbar();
    const {currentToken, setToken} = UseToken();

    const dispatch = useDispatch();
    const { versions, status, error } = useSelector((state) => state.frameDataVersions);

    useEffect(() => {
        dispatch(fetchFrameDataVersion({ frameDataApiUrl, currentToken }));
    }, [dispatch, frameDataApiUrl, currentToken]);

    useEffect(() => {
    }, [selectedBank]);

    if(status === "loading"){
        return (
            <PageContainer title="Sample Page" description="this is Sample page">
                <DashboardCard>
                    <Skeleton />
                </DashboardCard>
            </PageContainer>
        )
    }
    else if (status === "succeeded") {
        // debugger;
        return (
            <>
                <TableContainer component={Paper} style={{paddingBottom: "1rem"}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableHeaderCell>Банк</StyledTableHeaderCell>
                                <StyledTableHeaderCell>Описание</StyledTableHeaderCell>
                                <StyledTableHeaderCell>Дата генерации</StyledTableHeaderCell>
                                <StyledTableHeaderCell>На дату</StyledTableHeaderCell>
                                <StyledTableHeaderCell>Тип</StyledTableHeaderCell>
                                <StyledTableHeaderCell>Инициатор</StyledTableHeaderCell>
                                <StyledTableHeaderCell>Статус</StyledTableHeaderCell>
                                <StyledTableHeaderCell>Управление</StyledTableHeaderCell>
                                <StyledTableHeaderCell>-</StyledTableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {versions.map((genData, index) => (
                                    <SingleGenData data={genData}/>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }
}

function SingleGenData({data}) {

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const {currentToken, setToken} = UseToken();
    const [modalOpen, setModalOpen] = useState(false);
    const [jobDetailsOpen, setJobDetailsOpen] = React.useState({});
    const job = useSelector(state => selectJobByProcessId(state, data.jobId));

    function GenerationStatusChip({generation}) {
        const color = getColorGenerationStatusesByKeyword(generation.status.toUpperCase());
        return <Chip label={<Typography variant="uptitleBold3">{translateGenerationStatus(generation.status.toUpperCase())}</Typography>}
                     style={{border: "2px solid", borderColor: color, backgroundColor: "unset"}}/>;
    }


    function loadJobsForGenerationProcess() {
        // debugger;
        if (data.jobId) {
            AxiosGetWithoutState(jobServiceApiUrl + "/jobRequest/find/byProcessId", "GET", {processId: data.jobId}, currentToken)
                .then(({loaded, error, data}) => {
                    if (error) {
                        enqueueSnackbar(error, {variant: "error"});
                        console.log("Не удалось получить задачи по загрузке для файла: \n" + error);
                    } else {
                        // debugger;
                        dispatch(addJobList(data));
                    }
                }).catch(error => {
                enqueueSnackbar("Не удалось получить задачи", {variant: "error"});
                console.log("Не удалось получить задачи: \n" + error);
            });
        }
    }

    const showJobProcess = (id) => {
        if (id)
            loadJobsForGenerationProcess();
        setJobDetailsOpen({...jobDetailsOpen, [id]: !jobDetailsOpen[id]});
    };

    const handleModalOpen = (genId) => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <React.Fragment key={data.id}>
            <TableRow key={data.id}>
                <StyledTableCell>{getNameBankByKeyword(data.bank)}</StyledTableCell>
                <StyledTableCell>{data.description}</StyledTableCell>
                <StyledTableCell>{data.createdAt}</StyledTableCell>
                <StyledTableCell>{formatDate(data.generatedOnDate)}</StyledTableCell>
                <StyledTableCell>{data.operationMode}</StyledTableCell>
                <StyledTableCell>{"-"}</StyledTableCell>
                <StyledTableCell><GenerationStatusChip generation={data}/></StyledTableCell>
                <TableCell align={"center"}><IconSettings onClick={() => handleModalOpen(data.id)}
                                                          style={{cursor: 'pointer'}}/></TableCell>
                <TableCell align="center">
                    <IconButton size="small" onClick={() => showJobProcess(data.jobId)}>
                        {jobDetailsOpen[data.jobId] ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                    <Collapse in={jobDetailsOpen[data.jobId]} timeout="auto" unmountOnExit>
                        <Table aria-label="purchases" className="nested-table">
                            <TableBody
                                style={{backgroundColor: "#00c6ff17", border: "1px solid #c2c2c2"}}>
                                <TableRow>
                                    <TableCell style={{border: "1px solid #c2c2c2"}} align={"center"}
                                               sx={{color: "#2e2e2e", fontWeight: 800}}>ID</TableCell>
                                    <TableCell style={{border: "1px solid #c2c2c2"}}
                                               align={"center"} sx={{
                                        color: "#2e2e2e",
                                        fontWeight: 800
                                    }}>Параметры</TableCell>
                                    <TableCell style={{border: "1px solid #c2c2c2"}}
                                               align={"center"} sx={{
                                        color: "#2e2e2e",
                                        fontWeight: 800
                                    }}>Статус</TableCell>
                                    <TableCell style={{border: "1px solid #c2c2c2"}}
                                               align={"center"}
                                               sx={{color: "#2e2e2e", fontWeight: 800}}>Время до
                                        окончания</TableCell>
                                    <TableCell style={{border: "1px solid #c2c2c2"}}
                                               align={"center"} sx={{
                                        color: "#2e2e2e",
                                        fontWeight: 800
                                    }}>Инфо</TableCell>
                                </TableRow>
                                {job ? <JobContainer key={data.jobId} processId={data.jobId}/> : <></>}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
            {modalOpen && (
                <GeneratedDataManage
                    isOpen={modalOpen}
                    onClose={handleModalClose}
                />
            )}
        </React.Fragment>
    )
}


function GeneratedDataManage({url, title, isOpen, onClose}) {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();

        return (
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>{"Заголовок генерации"}</DialogTitle>
                <DialogContent sx={{p: '1rem !important'}}>
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Box >
                                            <Typography variant="h6">Скачать файлы</Typography>
                                        </Box>
                                        <Box sx={{mt: '0.5rem'}}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Button variant="contained">Все</Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained">Offers</Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained">Clients</Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained">Operations</Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>

                                        <Box>
                                            <Typography variant="h6">Обновить файлы</Typography>
                                        </Box>

                                        <Box sx={{mt: '0.5rem'}}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Button variant="contained">Offers</Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained">Media</Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained">Добавить файл</Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box sx={{ mt: 2, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                                    <Box>
                                        <Typography variant="h6">Генерация файлов на май</Typography>
                                    </Box>
                                </Box>

                                <Grid container spacing={1} alignItems="center" textAlign="center">
                                    <Grid item xs={2.5} >
                                        <Box sx={{
                                            backgroundColor: '#5d87ff', // Синий цвет фона
                                            borderRadius: '1rem', // Скругление углов
                                            padding: '0.2rem 0.5rem', // Внутренние отступы
                                            display: 'flex', // Flexbox для выравнивания
                                            alignItems: 'center', // Центрирование по вертикали
                                            color: '#fff', // Белый цвет текста
                                            fontWeight: 'bold', // Жирный текст
                                            justifyContent: 'center' // Центрирование по горизонтали
                                        }}>

                                            <Typography variant="body1" sx={{ flex: 1, textAlign: 'center', textOverflow: 'ellipsis' }}>36501</Typography>
                                            <Box sx={{ mx: 1 }}> | </Box> {/* Разделитель */}
                                            <Typography variant="body1" sx={{ flex: 1, textAlign: 'center', textOverflow: 'ellipsis' }}>Леонардо</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </>
                </DialogContent>
            </Dialog>
        );
}

// function AdmitadBillingLogRow({id,index, log}) {
//     return(
//         <DashboardCard>
//             <Grid container spacing={2}>
//                 <Grid item xs={9}>
//                     <Grid container spacing={2}>
//                         <Grid item xs={6}>
//                                 <Box >
//                                     <Typography variant="h6">Скачать файлы</Typography>
//                                 </Box>
//                                 <Box sx={{mt: '0.5rem'}}>
//                                 <Grid container spacing={2}>
//                                     <Grid item>
//                                         <Button variant="contained">Все</Button>
//                                     </Grid>
//                                     <Grid item>
//                                         <Button variant="contained">Offers</Button>
//                                     </Grid>
//                                     <Grid item>
//                                         <Button variant="contained">Clients</Button>
//                                     </Grid>
//                                     <Grid item>
//                                         <Button variant="contained">Operations</Button>
//                                     </Grid>
//                                 </Grid>
//                                 </Box>
//                         </Grid>
//                         <Grid item xs={6}>
//
//                                 <Box>
//                                     <Typography variant="h6">Обновить файлы</Typography>
//                                 </Box>
//
//                             <Box sx={{mt: '0.5rem'}}>
//                                 <Grid container spacing={2}>
//                                     <Grid item>
//                                         <Button variant="contained">Offers</Button>
//                                     </Grid>
//                                     <Grid item>
//                                         <Button variant="contained">Media</Button>
//                                     </Grid>
//                                     <Grid item>
//                                         <Button variant="contained">Добавить файл</Button>
//                                     </Grid>
//                                 </Grid>
//                             </Box>
//                         </Grid>
//                     </Grid>
//                     <Box sx={{ mt: 2, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
//                         <Box>
//                             <Typography variant="h6">Генерация файлов на май</Typography>
//                         </Box>
//                     </Box>
//
//                     <Grid container spacing={1} alignItems="center" textAlign="center">
//                         <Grid item xs={2.5} >
//                             <Box sx={{
//                                 backgroundColor: '#5d87ff', // Синий цвет фона
//                                 borderRadius: '1rem', // Скругление углов
//                                 padding: '0.2rem 0.5rem', // Внутренние отступы
//                                 display: 'flex', // Flexbox для выравнивания
//                                 alignItems: 'center', // Центрирование по вертикали
//                                 color: '#fff', // Белый цвет текста
//                                 fontWeight: 'bold', // Жирный текст
//                                 justifyContent: 'center' // Центрирование по горизонтали
//                             }}>
//
//                                 <Typography variant="body1" sx={{ flex: 1, textAlign: 'center', textOverflow: 'ellipsis' }}>36501</Typography>
//                                 <Box sx={{ mx: 1 }}> | </Box> {/* Разделитель */}
//                                 <Typography variant="body1" sx={{ flex: 1, textAlign: 'center', textOverflow: 'ellipsis' }}>Леонардо</Typography>
//                             </Box>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </Grid>
//         </DashboardCard>
//     )
// }


function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default FrameDataGenerationView;
