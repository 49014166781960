import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AxiosGetWithoutState} from "../../../axios/AxiosGet";


// Асинхронный thunk для получения данных о файлах приоритетов
export const fetchPriorityFiles = createAsyncThunk(
    'priorityFiles/fetchPriorityFiles',
    async ({ frameDataApiUrl, currentToken }, { rejectWithValue }) => {
        try {
            // debugger;

            const { loaded, error, data } = await AxiosGetWithoutState(frameDataApiUrl + '/priority_file', "GET", {}, currentToken);
            if (error) {
                return rejectWithValue(error);
            }
            return data;
        } catch (err) {
            return rejectWithValue('Не удалось получить информацию о загруженных файлах приоритетов');
        }
    }
);

// Селектор для получения файлов в нужном формате
export const selectTransformedFilesAsParams = (state) => {
    const files = state.priorityFiles.files; // Берем файлы из стора

    // debugger;
    const transformedFiles = files.map(file => ({
        key: file.id, // Используем id как ключ (число)
        value: file.id + ". " + file.description // Используем описание как значение
    }));

    // Сортировка по ключу key (число)
    transformedFiles.sort((a, b) => a.key - b.key); // Сортировка по возрастанию


    return transformedFiles;
};

const priorityFileSlice = createSlice({
    name: 'priorityFiles',
    initialState: {
        files: [],
        status: 'idle', // idle | loading | succeeded | failed
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPriorityFiles.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchPriorityFiles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.files = action.payload.sort((a, b) => b.id - a.id);
            })
            .addCase(fetchPriorityFiles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

// Экспортируем slice целиком
export default priorityFileSlice;
