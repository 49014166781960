import React from 'react';
import {TableCell, Typography} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const StyledTableHeaderCell = ({ children, ...props }) => {
    const theme = useTheme();

    return (
        <TableCell
            align="center"
            sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.light,
                fontWeight: 800
            }}
            {...props}
        >
            {children}
        </TableCell>
    );
};

const customTableCellStyle = {
    padding: '8px 10px', // Настройте отступы по необходимости
};

export const StyledTableCell = ({ children, ...props }) => {
    return (
        <TableCell align="center"  sx={customTableCellStyle} {...props}>
            <Typography variant="uptitleBold3">{children}</Typography>
        </TableCell>
    );
};
