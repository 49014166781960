import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';


export const jobsSlice = createSlice({
    name: 'jobs',
    initialState: [],
    reducers: {
        // Редьюсер для обновления данных процесса
        addProcessInfoByList: (state, action) => {

            const processInfoList = action.payload;
            processInfoList.forEach(processInfo => {
                // Проверяем, существует ли уже процесс с таким processId в state
                const existingJobIndex = state.findIndex(p => p.processId === processInfo.processId);
                if (existingJobIndex === -1) {
                    console.log("Пришел process Info, но нет job которому его можно присвоить")
                } else {
                    // Если процесс с таким processId уже существует, обновляем его данные
                    state[existingJobIndex] = {
                        ...state[existingJobIndex], processInfo: {
                            processId: processInfo.processId,
                            storageType: processInfo.storageType,
                            expectedTime: processInfo.expectedTime,
                            spentTime: processInfo.spentTime,
                            processStatus: processInfo.processStatus,
                            createdDate: processInfo.createdDate
                        }
                    };
                }
            });
        },
        updateProcessInfo: (state, action) => {
            const processInfo = action.payload;

                // Проверяем, существует ли уже процесс с таким processId в state
                const existingJobIndex = state.findIndex(p => p.processId === processInfo.processId);
                if (existingJobIndex === -1) {
                    console.log("Пришел process Info, но нет job которому его можно присвоить")
                } else {
                    // Если процесс с таким processId уже существует, обновляем его данные
                    state[existingJobIndex] = {
                        ...state[existingJobIndex], processInfo: {
                            processId: processInfo.processId,
                            storageType: processInfo.storageType,
                            expectedTime: processInfo.expectedTime,
                            spentTime: processInfo.spentTime,
                            processStatus: processInfo.processStatus,
                            createdDate: processInfo.createdDate
                        }
                    };
                }
        },
        addJob: (state, action) => {
            const job = action.payload;
            // Проверяем, существует ли уже процесс с таким processId в state
            const existingJobIndex = state.findIndex(p => p.processId === job.processId);
            if (existingJobIndex === -1) {
                // Если процесса с таким processId нет, добавляем новый объект processInfo в state
                state.push({
                    id: job.id,
                    bank: job.bank,
                    processId: job.processId,
                    subId: job.subId,
                    created: job.created,
                    createdBy: job.createdBy,
                    status: job.status,
                    launchParamString: job.launchParamString,
                    processInfo: null
                });
            } else {
                // Если процесс с таким processId уже существует, обновляем его данные
                state[existingJobIndex] = { ...state[existingJobIndex], ...job };
            }
        },
        cleanJobList: (state) => {
            return []; // Возвращаем пустой массив, очищая состояние
        },
        addJobList: (state, action) => {

            const jobList = action.payload; // Получаем массив объектов

            jobList.forEach(job => {
                const existingJobIndex = state.findIndex(j => j.processId === job.processId);
                if (existingJobIndex === -1) {
                    // Если процесса с таким processId нет
                    state.push({
                        id: job.id,
                        bank: job.bank,
                        processId: job.processId,
                        subId: job.subId,
                        created: job.created,
                        createdBy: job.createdBy,
                        processStatus: job.processStatus,
                        launchParamString: job.launchParamString,
                        processInfo: null
                    });
                } else {
                    // Если процесс с таким processId уже существует, обновляем его данные
                    state[existingJobIndex] = { ...state[existingJobIndex], ...job };
                }
            });

            // // Затем создаем новый массив для фильтрации, не мутируя текущий state
            // const now = new Date();
            // const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
            // const newState = state.filter(job => {
            //     const jobStartDate = new Date(job.created);
            //     return jobStartDate > oneWeekAgo;
            // });
            //
            // // Очищаем текущее состояние
            // state.splice(0, state.length);
            //
            // // Заполняем текущее состояние отфильтрованными значениями
            // newState.forEach(job => state.push(job));
        },
    }
});

export const selectJobList = state => state.jobs;

// Выбор списка джобов по id
export const selectListJobById = createSelector(
    [selectJobList, (state, id) => id.toString()],
    (jobs, id) => {
        return jobs.filter(job => job.id.toString() === id.toString())
    }
);

// Выбор списка по processIds
export const selectJobsByProcessIds = createSelector(
    [selectJobList, (state, processIds) => processIds],
    (jobs, processIds) => jobs.filter(job => processIds.includes(job.processId)).map(job => job.processId)
);


export const selectCommonInfoJob = createSelector(
    [selectListJobById],
    (filteredJobs) => {
        // Поскольку selectJobsById уже фильтрует jobs, filteredJobs здесь — это результат этой фильтрации
        const processes = filteredJobs.map(job => job.processInfo).filter(info => info !== null);

        if(filteredJobs.length > 0 && (filteredJobs[0].processStatus === "COMPLETED" || filteredJobs[0].processStatus === "ERROR" || filteredJobs[0].processStatus === "TO_STOP" || filteredJobs[0].processStatus === "STOPPED"))
            return {
                bank: filteredJobs[0].bank,
                created: filteredJobs[0].created,
                createdBy: filteredJobs[0].createdBy,
                processStatus: filteredJobs[0].processStatus,
                slowlyJob: getCommonExecutionTime(null)
            }

        // Здесь мы берем первую задачу из отфильтрованного списка для общей информации,
        // предполагая, что все задачи имеют одинаковые значения в интересующих нас полях
        return filteredJobs.length > 0 ? {
                bank: filteredJobs[0].bank,
                created: filteredJobs[0].created,
                createdBy: filteredJobs[0].createdBy,
                processStatus:  getCommonStatus(processes),
                slowlyJob: getCommonExecutionTime(processes)
            } :
            {
                bank: "Ожидаем данные",
                created: "Ожидаем данные",
                createdBy: "Ожидаем данные",
                processStatus: [],
                slowlyJob: []
            };
    }
);

export const selectProcessIdsSortedSubIdsById = createSelector(
    [selectListJobById],
    (filteredJobs) => {
        if(filteredJobs)
            return filteredJobs.map(job => job.processId).sort(sortBySubId);
        else return [];
    }
);

// Селектор для поиска задания по subId
export const selectJobByProcessId = createSelector(
    [selectJobList, (_, processId) => processId],
    (jobs, processId) => {
        // debugger;
        return jobs.find(job => job.processId === processId)
    }
);

// Функция для сортировки по subId
function sortBySubId(a, b) {
    const subIdA = parseInt(a.split('-')[1], 10);
    const subIdB = parseInt(b.split('-')[1], 10);

    // Проверяем, удалось ли успешно разобрать числа
    if (!isNaN(subIdA) && !isNaN(subIdB)) {
        return subIdA - subIdB;
    } else {
        // Если не удалось разобрать числа, оставляем элементы в исходном порядке
        return 0;
    }
}


function getCommonStatus(processList){
    if(processList.length === 0)
        return "Ожидаем данные";


    // Извлекаем все статусы
    const statuses = processList.map(process => process.processStatus);

    // Проверяем условия
    const hasSendToQueue = statuses.some(status => status === 'SENT_TO_EXECUTE');
    const hasError = statuses.some(status => status === 'ERROR');
    const allFinished = statuses.every(status => status === 'COMPLETED');
    const allRunning = statuses.every(status => status === 'RUNNING');
    const allCreated = statuses.every(status => status === 'CREATED');
    const allStopped = statuses.every(status => status === 'STOPPED');

    // Возвращаем соответствующий статус
    if (hasSendToQueue) {
        return translateStatus('SENT_TO_EXECUTE');
    }
    if (hasError) {
        return translateStatus('ERROR');
    }
    if (allStopped) {
        return translateStatus('STOPPED');
    }
    else if (allRunning) {
        return translateStatus('RUNNING');
    }
    else if (allCreated) {
        return translateStatus('CREATED');
    }
    else if (allFinished) {
        return translateStatus('COMPLETED');
    } else {
        // Возвращаем какое-то другое значение или обрабатываем иначе
        // в зависимости от вашей логики
        return translateStatus('UNKNOWN');
    }
}

const getCommonExecutionTime = (processList) => {
    if(!processList)
        return null;
    // Проверяем, что все задачи в статусе RUNNING или COMPLETED
    const allRunningOrCompleted = processList.every(pi =>
        pi.processStatus === 'RUNNING' || pi.processStatus === 'COMPLETED'
    );

    if (!allRunningOrCompleted) {
        return null; // или другое соответствующее действие
    }

    // Фильтруем задачи, которые не в статусе FINISHED и сортируем по expectedTime
    const sortedJobs = processList
        .filter(pi => pi.processStatus === 'RUNNING')
        .sort((a, b) => b.expectedTime - a.expectedTime);

    // Возвращаем задачу с максимальным expectedTime
    return sortedJobs.length > 0 ? sortedJobs[0] : null;
};


export const ProcessStatuses = [
    { name: "Подготовка к выполнению", id: "PREPARE_TO_EXECUTE", color: 'rgb(0,123,17)' },
    { name: "Отправлено на выполнение", id: "SENT_TO_EXECUTE", color: 'rgb(230,157,0)' },
    { name: "Ошибка отправки на выполнение", id: "ERROR_SEND_TO_EXECUTE", color: 'rgb(149,2,2)' },
    { name: "Ожидание", id: "WAITING", color: 'rgb(196,188,27)' },
    { name: "Выполняется", id: "RUNNING", color: 'rgb(0, 0, 255)' },
    { name: "Завершено", id: "COMPLETED", color: 'rgb(0,123,17)' },
    { name: "Ошибка", id: "ERROR", color: 'rgb(149,2,2)' },
    { name: "Добавлено в очередь", id: "SENT_TO_QUEUE", color: 'rgb(196,188,27)' },
    { name: "Создана", id: "CREATED", color: 'rgb(51,135,0)' },
    { name: "В очереди", id: "IN_QUEUE", color: 'rgb(196,188,27)' },
    { name: "Нехватило памяти", id: "NEED_MORE_MEMORY", color: 'rgb(149,2,2)' },
    { name: "Недостаточно памяти", id: "NOT_ENOUGH_MEMORY", color: 'rgb(149,2,2)' },
    { name: "Перезапущен из за нехватки памяти", id: "RESTARTED", color: 'rgb(230,157,0)' },
    { name: "Выполняется остановка", id: "TO_STOP", color: 'rgb(198,119,0)' },
    { name: "Остановлен", id: "STOPPED", color: 'rgb(206,125,14)' }
];

export function translateStatus(status) {
    if(!status)
        return 'Ожидаем статус';
    const statusDetails = ProcessStatuses.find(s => s.id === status);
    return statusDetails ? statusDetails.name : 'Неизвестный статус';
}

export function getColorProcessStatusByKeyword(status) {
    const statusDetails = ProcessStatuses.find(s => s.id === status);
    return statusDetails ? statusDetails.color : 'rgb(128, 128, 128)'; // Возвращаем серый цвет, если статус не найден
}

export const { addProcessInfoByList, updateProcessInfo, addJob, addJobList, cleanJobList } = jobsSlice.actions;
