import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Skeleton, Typography} from '@mui/material';
import PageContainer from '../../components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import TypeSwitchBar from "../../layouts/full/TypeSwitchBar/TypeSwitchBar";
import {IconLayoutDashboard} from "@tabler/icons";
import {useSnackbar} from "notistack";
import {AxiosGet, AxiosGetWithoutState} from "../../axios/AxiosGet";
import {fileServiceApiUrl, jobServiceApiUrl, processServiceApiUrl} from "../../schema/Environment";

import JobTableContainer from "./JobsTable";
import JobLauncherBox from "./JobLauncherBox";
import UseToken from "../../auth/UseToken";
import {useDispatch} from "react-redux";
import {addFileInfoList} from "./reducers/fileSlice";
import {addJobList, addProcessInfoByList, cleanJobList} from "./reducers/jobsSlice";


function JobLauncherView ({group}) {
    const { enqueueSnackbar } = useSnackbar();
    const {loaded, error, data} = AxiosGet(jobServiceApiUrl + "/job/find/all", "GET", null, enqueueSnackbar);
    const [selectedTypeId, setSelectedTypeId] = useState(null);

    if(loaded === false && !error){
        return (
            <PageContainer title="Sample Page" description="this is Sample page">
                <DashboardCard>
                    <Skeleton />
                </DashboardCard>
            </PageContainer>
        )
    }

    if(data) {
        let jobGroupByLaunchTypeMap = new Map();
        data.filter(job => job.type === group).forEach((job) => {
            jobGroupByLaunchTypeMap.set(job.id, job);
        })


        let launchTypeItems = Array.from(jobGroupByLaunchTypeMap, ([key, job]) => ({
            id: job.launchType,
            title: job.title,
            icon: IconLayoutDashboard
        }));


        return (
            <PageContainer title="Sample Page" description="this is Sample page">
                <TypeSwitchBar title={'Тип задачи'} items={launchTypeItems} valueSetter={setSelectedTypeId} selectedId={selectedTypeId}/>
                <JobDataView jobs={data} group={group} selectedTypeId={selectedTypeId}/>
            </PageContainer>
        );
    }
}

function JobDataView ({jobs, group, selectedTypeId}){
    const [selectedJob, setSelectedJob] = useState(jobs.find(job => job.id === selectedTypeId));
    const [refreshMark, setRefreshMark] = useState(null);
    const [selectedValues, setSelectedValues] = useState({
        selectedTypeId: selectedTypeId
    },);

    useEffect(() => {
        setSelectedValues({
            selectedTypeId: selectedTypeId
        });
        setSelectedJob(jobs.find(job =>  job.launchType === selectedTypeId));
    }, [selectedTypeId, jobs]);

    return (
        <>
            {selectedJob ?
                <>
                    <JobLauncherBox job={selectedJob} url={jobServiceApiUrl + "/jobRequest/create"}/>
                    <JobTableContainer group={group} type={selectedValues.selectedTypeId}/>
                    <UploadPreviousPeriod group={group} type={selectedValues.selectedTypeId}/>
                </> : <></>}
        </>
    );
}


function UploadPreviousPeriod({group, type}) {
    const {enqueueSnackbar} = useSnackbar();
    const {currentToken, setToken} = UseToken();
    const [endDate, setEndDate] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        setEndDate(date);
    }, []);

    function loadJobs(countDays){
        setLoading(true);
        setError(null);

        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - countDays);

        AxiosGetWithoutState(jobServiceApiUrl + "/jobRequest/find/allByJobGroupAndTypeAndDateRange", "GET", { jobGroup: group, jobType: type, startDate: startDate.toISOString().split('T')[0], endDate: endDate.toISOString().split('T')[0] }, currentToken).then(({loaded, error, data}) => {

            // debugger;
            if(error){
                enqueueSnackbar(error, {variant: "error"});
            }
            else {
                dispatch(addJobList(data));
            }
        }).catch(error => {
            enqueueSnackbar("Не удалось получить задачи", {variant: "error"});
            console.log("Не удалось получить задачи: \n" + error);
        });
        setLoading(false);
    };

    return (
        <>
            <Box sx={{height: "2rem"}}></Box>
            <DashboardCard >
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item xs={6} alignItems="center">
                        <Typography variant="h6">Добавить данные</Typography>
                    </Grid>
                    <Grid item xs={6} alignItems="center" style={{ textAlign: 'right' }}>
                        <Grid container spacing={2}  justifyContent="flex-end">
                            <Grid item alignItems="center">
                                <Button variant="contained" color="primary" onClick={() => loadJobs(7)}>
                                    За неделю
                                </Button>
                            </Grid>
                            <Grid item alignItems="center">
                                <Button variant="contained" color="primary" onClick={() => loadJobs(30)}>
                                    За месяц
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DashboardCard>
        </>
    );
}


export default JobLauncherView;
