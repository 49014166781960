import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export default function SelectField (props) {

    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        if (props.handleChange) {
            props.handleChange(props.id, selectedValue);
        }
    }, [selectedValue]);


    const sortedValues = props.itemList;
        if(props.paramsSorted === undefined || props.paramsSorted !== true)
            sortedValues.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));

    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{props.label ? props.label : "Значение"}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedValue}
                label={props.label ? props.label : "Значение"}
                onChange={handleChange}
            >
                {sortedValues.map((item) => (
                    <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
