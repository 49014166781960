import React, {useMemo, useState, useEffect} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Typography, Collapse, Skeleton, Badge, Box, Grid, Chip
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CommentIcon from "@mui/icons-material/Comment";
import { LinearProgress } from "@mui/material";
import timeUtils from "../../TimeUtil";
import { useTheme } from '@mui/material/styles';
import {groupBy, reverse, sortBy} from "lodash";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import "../../../src/table.css";
import PageContainer from "../../components/container/PageContainer";
import DashboardCard from "../../components/shared/DashboardCard";
import RowsDataModal from "../modal/RowsDataModal";
import {AxiosGet, AxiosGetWithoutState} from "../../axios/AxiosGet";
import {processServiceApiUrl, jobServiceApiUrl} from "../../schema/Environment";
import { styled } from '@mui/system';
import {useSnackbar} from "notistack";
import {AxiosPostWithoutState} from "../../axios/AxiosPost";
import UseToken from "../../auth/UseToken";
import TypeSwitchBar from "../../layouts/full/TypeSwitchBar/TypeSwitchBar";
import {IconLayoutDashboard} from "@tabler/icons";

import {useDispatch, useSelector} from 'react-redux';
import {Banks, getColorBankByKeyword, getNameBankByKeyword, setSelectedTypeId} from "./reducers/bankFilterSlice";

import {
    addJobList,
    addProcessInfoByList, cleanJobList, getColorProcessStatusByKeyword,
    selectCommonInfoJob,
    selectJobByProcessId,
    selectProcessIdsSortedSubIdsById,
    translateStatus, updateProcessInfo
} from "./reducers/jobsSlice";
import {getWebSocket} from "./store/webSocket/websocketManager";
import {IconBuildingBank} from "@tabler/icons-react";



const IconButtonWithMultipleBadge = styled(IconButton)(({ theme }) => ({
    position: 'relative',
}));

const ErrorBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: theme.palette.error.light,
        color: 'white',
    },
}));

const WarningBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor:  theme.palette.warning.light,
        color: 'white',
    },
}));

function JobTableContainer({group, type}) {
    const dispatch = useDispatch();
    const {currentToken, setToken} = UseToken();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        AxiosGetWithoutState(jobServiceApiUrl + "/jobRequest/find/allByJobGroupAndType", "GET", { jobGroup: group, jobType: type }, currentToken).then(({loaded, error, data}) => {

            if(error){
                enqueueSnackbar(error, {variant: "error"});
                console.log("Не удалось получить задачи: \n" + error);
            }
            else {
                dispatch(cleanJobList())
                dispatch(addJobList(data));

                // Добавляем инвормацию о выполнении задачи
                AxiosGetWithoutState(processServiceApiUrl + "/process/find/allForDay", "GET", null, currentToken).then(({loaded, error, data}) => {

                    if(error){
                        enqueueSnackbar("Не удалось получить статусы процессов: \n" + error, {variant: "error"});
                        console.log("Не удалось получить статусы процессов: \n" + error);
                    }
                    else {
                        dispatch(addProcessInfoByList(data));
                    }
                }).catch(error => {
                    enqueueSnackbar("Не удалось получить статусы процессов", {variant: "error"});
                    console.log("Не удалось получить статусы процессов: \n" + error);
                });
            }
        }).catch(error => {
            enqueueSnackbar("Не удалось получить задачи", {variant: "error"});
            console.log("Не удалось получить задачи: \n" + error);
        });
    }, [group, type]);

        return (
            <JobTableContent/>
        )
}

function BankFilter({jobs}) {
    const dispatch = useDispatch();
    const [selectedTypeId, setSelectedTypeIdLocal] = useState(null);

    let groupsByBank;
    try {
        groupsByBank = groupBy(jobs, "bank");
    }catch (e){

    }

    let names = [];

    if(groupsByBank && Object.entries(groupsByBank).length > 0)
        names = Object.entries(groupsByBank).map(g => g[0]);

    const handleSelect = (id) => {

        setSelectedTypeIdLocal(id);
        dispatch(setSelectedTypeId(id));
    };

    let banksList = Banks.filter(b => names.includes(b.id)).sort((a, b) => a.name.localeCompare(b.name));


    let bankItems = Array.from(banksList, (item) => ({
        id: item.id,
        title: item.name,
        icon: () => <IconBuildingBank stroke={2} color={item.id ? getColorBankByKeyword(item.id) : 'grey'}/>
    }));

    if(bankItems.length > 0)
        return (
            <TypeSwitchBar title={'Фильтр. Банк'} items={bankItems} valueSetter={handleSelect} selectedId={selectedTypeId} gridSize={"SMALL"}/>
        );
    else
        return <></>;
}

const JobTableContent = () => {
    const theme = useTheme();
    const jobs = useSelector((state) => state.jobs);
    const selectedBankFilter = useSelector((state) => state.bankFilter.selectedTypeId);


    let groups = groupBy(jobs, "id");
    if(selectedBankFilter){
        groups = groupBy(jobs.filter(j => j.bank === selectedBankFilter), "id");
    }

    if(jobs.length > 0)
        return (
            <>
                <BankFilter jobs={jobs}/>
                <TableContainer component={Paper} style={{paddingBottom: "1rem"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Банк</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Дата создания</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Инициатор</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Статус</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Время до окончания</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Удалить</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>-</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(groups)
                            .sort(([keyA], [keyB]) => parseInt(keyB) - parseInt(keyA))
                            .map(([id, jobsGroup]) => (
                                    <ProcessListContent key={id} id={id} />
                            ))}
                    </TableBody>

                </Table>
            </TableContainer>
                </>
        );
    else{
        return (
            <PageContainer title="Sample Page" description="this is Sample page">
                <DashboardCard>
                    <Skeleton />
                </DashboardCard>
            </PageContainer>
        )
    }
};


const ProcessListContent = React.memo(({id}) => {

    const { enqueueSnackbar } = useSnackbar();
    const {currentToken, setToken} = UseToken();
    const [open, setOpen] = React.useState({});
    const jobProcessIds = useSelector(state => selectProcessIdsSortedSubIdsById(state, id));
    const commonInfoJob = useSelector(state => selectCommonInfoJob(state, id));




    const showJobDetails = (id) => {
        setOpen({ ...open, [id]: !open[id] });
    };

    const stopJobById = (jobId) => {
        AxiosPostWithoutState(jobServiceApiUrl + "/jobRequest/deleteJobById",{jobId: jobId}, null,  currentToken).then(({loaded, error, data}) => {
            if(error){
                enqueueSnackbar(error, {variant: "error"});
            }
            else {
                enqueueSnackbar(data, {variant: "success"});
            }
        }).catch(error => {
            console.log(error);
            enqueueSnackbar("Непредвиденная ошибка при удалении задачи", {variant: "error"});
        });
    }


    return (
        <>
        <React.Fragment key={id}>
            <TableRow hover>
                <TableCell align="center"> <Typography variant="uptitleBold3">{getNameBankByKeyword(commonInfoJob.bank)}</Typography></TableCell>
                <TableCell align="center"> <Typography variant="uptitleBold3">{commonInfoJob.created}</Typography></TableCell>
                <TableCell align="center"> <Typography variant="uptitleBold3">{commonInfoJob.createdBy}</Typography></TableCell>
                <TableCell align="center"> <Typography variant="uptitleBold3"><ProcessStatusChip job={commonInfoJob}/></Typography></TableCell>
                <TableCell align="center">
                    {commonInfoJob.slowlyJob ?
                        <TimeUntilCompletion spentTime={commonInfoJob.slowlyJob.spentTime} expectedTime={commonInfoJob.slowlyJob.expectedTime}/>
                        :
                        '-'
                    }

                </TableCell>
                <TableCell align="center">
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <IconButton onClick={() => stopJobById(id)}>
                            <DeleteIcon color="black"/>
                        </IconButton>
                    </div>
                </TableCell>
                <TableCell align="center">
                    <IconButton size="small" onClick={() => showJobDetails(id)}>
                        {open[id] ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                    <Collapse in={open[id]} timeout="auto" unmountOnExit>
                        <Table aria-label="purchases" className="nested-table">
                            <TableBody
                                style={{backgroundColor: "#00c6ff17", border: "1px solid #c2c2c2"}}>
                                <TableRow>
                                    <TableCell style={{border: "1px solid #c2c2c2"}} align={"center"} sx={{color: "#2e2e2e", fontWeight: 800}}>ID</TableCell>
                                    <TableCell style={{border: "1px solid #c2c2c2"}}
                                               align={"center"} sx={{
                                        color: "#2e2e2e",
                                        fontWeight: 800
                                    }}>Параметры</TableCell>
                                    <TableCell style={{border: "1px solid #c2c2c2"}}
                                               align={"center"} sx={{
                                        color: "#2e2e2e",
                                        fontWeight: 800
                                    }}>Статус</TableCell>
                                    <TableCell style={{border: "1px solid #c2c2c2"}}
                                               align={"center"}
                                               sx={{color: "#2e2e2e", fontWeight: 800}}>Время до окончания</TableCell>
                                    <TableCell style={{border: "1px solid #c2c2c2"}}
                                               align={"center"} sx={{
                                        color: "#2e2e2e",
                                        fontWeight: 800
                                    }}>Инфо</TableCell>
                                </TableRow>
                                {jobProcessIds.map((processId) => (
                                    <JobContainer key={processId} processId={processId}/>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
            </>
    );

}
)

function ProcessStatusChip({job}) {
    // debugger;
    let status = "UNDEF";
    // debugger;

    // debugger;
    if(job)
        status = (
            job.processStatus === "COMPLETED" ||
            job.processStatus === "ERROR" ||
            job.processStatus === "TO_STOP" ||
            job.processStatus === "STOPPED" ||
            job.processStatus === "RESTARTED" ||
            job.processStatus === "NEED_MORE_MEMORY" ||
            job.processStatus === "NOT_ENOUGH_MEMORY") ? job.processStatus : job.processInfo?.processStatus;
    const color = getColorProcessStatusByKeyword(status);
    return <Chip label={<Typography variant="uptitleBold3">{translateStatus(status)}</Typography>}
                 style={{border: "2px solid", borderColor: color, backgroundColor: "unset"}}/>;
}

export const JobContainer = React.memo(({processId}) => {

    const [titleForModal, setTitleForModal] = React.useState(null);
    const [urlForModal, setUrlForModal] = React.useState(null);
    const [modalOpen, setModalOpen] = React.useState(false);
    const dispatch = useDispatch();
    const job = useSelector(state => selectJobByProcessId(state, processId));

    useEffect(() => {
        if(processId &&
            job.processInfo?.processStatus !== "COMPLETED" &&
            job.processInfo?.processStatus !== "ERROR" &&
            job.processInfo?.processStatus !== "TO_STOP" &&
            job.processInfo?.processStatus !== "STOPPED" &&
            job.processInfo?.processStatus !== "RESTARTED" &&
            job.processInfo?.processStatus !== "NEED_MORE_MEMORY" &&
            job.processInfo?.processStatus !== "NOT_ENOUGH_MEMORY") {

            // Функция обратного вызова для обработки сообщений

            const topic = '/processInfo/' + processId;

            const onMessage = (updatedProcessInfo) => {
                console.log("updatedProcessInfo: ", updatedProcessInfo);
                dispatch(updateProcessInfo(updatedProcessInfo));
            };

            const webSocket = getWebSocket('processService');
            // debugger;
            if (webSocket) {
                webSocket.subscribe(topic, onMessage);
            }
        }
    }, [processId]);


    const urlForJobLog = processServiceApiUrl +  "/checkpoint/find/all/byProcessId?processId=";
    const showLogModal = (url, title) => {
        setUrlForModal(url);
        setTitleForModal(title);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false)
    };

    debugger;
        return (
            <>
                <TableRow key={job.subId}>
                    <TableCell style={{border: "1px solid #c2c2c2"}}
                               align="center">
                        {job.subId}
                    </TableCell>
                    <TableCell style={{border: "1px solid #c2c2c2"}} sx={{maxWidth: "20rem"}}
                               align="center">
                        <ParametersTable parameters={job.launchParamString} job={job}/>
                    </TableCell>
                    <TableCell style={{border: "1px solid #c2c2c2"}}
                               align="center">
                        <Typography variant="uptitleBold3"><ProcessStatusChip job={job}/></Typography>
                    </TableCell>
                    <TableCell style={{border: "1px solid #c2c2c2"}}
                               align="center">
                        {job.processInfo !== null &&
                            job.processStatus !== 'COMPLETED' &&
                            job.processStatus !== 'ERROR' &&
                            job.processStatus !== "TO_STOP" &&
                            job.processStatus !== "STOPPED" &&
                            job.processStatus !== "RESTARTED" &&
                            job.processStatus !== "NEED_MORE_MEMORY" &&
                            job.processStatus !== "NOT_ENOUGH_MEMORY" ?
                            <TimeUntilCompletion spentTime={job.processInfo.spentTime} expectedTime={job.processInfo.expectedTime}/>
                            :
                            <></>
                        }
                    </TableCell>
                    <TableCell style={{border: "1px solid #c2c2c2"}}
                               align="center">
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <IconButtonWithMultipleBadge
                                onClick={() => showLogModal(urlForJobLog + job.processId, "Логи выполнения")}>
                                <CommentIcon color="secondary"/>
                                <Grid container direction={"row"}
                                      sx={{position: 'absolute', top: '-25%', right: '-50%'}}>

                                    {/*{processInfo['ERROR'] ? (*/}
                                    {/*    <Grid item xs={2}>*/}
                                    {/*        <ErrorBadge badgeContent={processInfo['ERROR']} size="small">*/}
                                    {/*            /!* Дополнительное содержимое для первого Badge *!/*/}
                                    {/*        </ErrorBadge>*/}
                                    {/*    </Grid>*/}
                                    {/*) : (<></>)}*/}

                                    {/*{processInfo['WARNING'] ? (*/}
                                    {/*    <Grid item xs={2}>*/}
                                    {/*        <WarningBadge badgeContent={processInfo['WARNING']} size="small">*/}
                                    {/*            /!* Дополнительное содержимое для второго Badge *!/*/}
                                    {/*        </WarningBadge>*/}
                                    {/*    </Grid>*/}
                                    {/*) : (<></>)}*/}
                                </Grid>
                            </IconButtonWithMultipleBadge>
                        </div>
                    </TableCell>
                </TableRow>
                <RowsDataModal title={titleForModal} url={urlForModal} isOpen={modalOpen} onClose={handleModalClose}/>
            </>
        )
})

function ParametersTable({ parameters, job }) {
    const jsonObject = JSON.parse(parameters);

    const map = new Map();
    for (const key in jsonObject) {
        if (jsonObject.hasOwnProperty(key)) {
            map.set(key, jsonObject[key]);
        }
    }
    return (
        <TableContainer >
            <Table>
                <TableBody>
                    {Array.from(map).map(([name, value]) => (
                        <TableRow key={name}>
                            <TableCell style={{borderBottom: "none"}} component="th" scope="row">
                                <Typography variant="body1" color="textSecondary">{name}</Typography>
                            </TableCell>
                            <TableCell style={{borderBottom: "none"}}><Typography variant="body1" color="textSecondary">{value}</Typography></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


function TimeUntilCompletion({expectedTime, spentTime }) {
    // expectedTime = 360;
    // spentTime = 180;

    const [localSpentTime, setLocalSpentTime] = useState(0);
    const [timerProperty, setTimerProperty] = useState({
        fullTime: 0,
        spentTime: 0
    })

    useEffect(() => {
        setTimerProperty({
            fullTime: (expectedTime + spentTime) / 1000,
            spentTime: spentTime / 1000
        })
    }, [expectedTime, spentTime]);

    useEffect(() => {
        setLocalSpentTime(timerProperty.spentTime);

        const timer = setInterval(() => {
            setLocalSpentTime(prevRemaining => {
                if (prevRemaining < timerProperty.fullTime) {
                    return prevRemaining + 1;
                } else {
                    clearInterval(timer);
                    return 0;
                }
            });
        }, 1000);

        return () => {
            console.log("unmount timer")
            clearInterval(timer); // Очистить интервал при размонтировании компонента
        };

    }, [timerProperty.spentTime, timerProperty.fullTime]);


    const progress = Math.min(1, localSpentTime / timerProperty.fullTime );

    return (
        <>
            <LinearProgress variant="determinate" value={progress * 100} />
            <Typography variant="uptitleBold3">{timeUtils().formatDuration(timerProperty.fullTime - localSpentTime)}</Typography>
        </>
    );
}

function sortBySubId(a, b) {
    const subIdA = parseInt(a.split('-')[1], 10);
    const subIdB = parseInt(b.split('-')[1], 10);

    // Проверяем, удалось ли успешно разобрать числа
    if (!isNaN(subIdA) && !isNaN(subIdB)) {
        return subIdA - subIdB;
    } else {
        // Если не удалось разобрать числа, оставляем элементы в исходном порядке
        return 0;
    }
}


export default JobTableContainer;
