import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AxiosGetWithoutState} from "../../../axios/AxiosGet";
import {FileStatuses} from "./fileSlice";


// Асинхронный thunk для получения данных о файлах приоритетов
export const fetchFrameDataVersion = createAsyncThunk(
    'frameDataVersions/fetchFrameDataVersion',
    async ({ frameDataApiUrl, currentToken }, { rejectWithValue }) => {
        try {
            const { loaded, error, data } = await AxiosGetWithoutState(frameDataApiUrl + '/version', "GET", {}, currentToken);
            if (error) {
                return rejectWithValue(error);
            }
            return data;
        } catch (err) {
            return rejectWithValue('Не удалось получить информацию о сгенерированных данных для фрейма');
        }
    }
);

const frameDataVersionsSlice = createSlice({
    name: 'priorityFiles',
    initialState: {
        versions: [],
        status: 'idle', // idle | loading | succeeded | failed
        error: null,
    },
    reducers: {
        addVersion: (state, action) => {
            // debugger;
            const newVersion = action.payload;
            state.versions.push({
                id: newVersion.id, // ID версии
                bank: newVersion.bank.toUpperCase(), // Преобразуем название банка в верхний регистр
                description: newVersion.description || 'Без описания', // Описание версии
                createdAt: newVersion.createdAt, // Дата создания
                generatedOnDate: newVersion.generatedOnDate, // Дата генерации
                operationMode: newVersion.operationMode || 'Не указан', // Режим работы
                status: newVersion.status || 'Неизвестен', // Статус версии
                jobId: newVersion.jobId, // ID задания
                bucketName: newVersion.bucketName || 'Не указано', // Имя бакета
            });
            state.versions.sort((a, b) => b.id - a.id);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFrameDataVersion.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchFrameDataVersion.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.versions = action.payload.sort((a, b) => b.id - a.id);
            })
            .addCase(fetchFrameDataVersion.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const GenerationStatuses = [
    { name: "Создан", id: "CREATED", color: 'rgb(0, 0, 255)' },
    { name: "Выполняется", id: "GENERATION", color: 'rgb(230,157,0)' },
    { name: "Завершено", id: "DONE", color: 'rgb(51,135,0)' },
    { name: "Ошибка", id: "ERROR", color: 'rgb(149,2,2)' },
    { name: "Нехватило памяти", id: "NEED_MORE_MEMORY", color: 'rgb(149,2,2)' },
    { name: "Выполняется остановка", id: "TO_STOP", color: 'rgb(198,119,0)' },
    { name: "Остановлен", id: "STOPPED", color: 'rgb(206,125,14)' }
];

export function getColorGenerationStatusesByKeyword(status) {
    const statusDetails = GenerationStatuses.find(s => s.id === status);
    return statusDetails ? statusDetails.color : 'rgb(128, 128, 128)'; // Возвращаем серый цвет, если статус не найден
}

export function translateGenerationStatus(status) {
    const statusDetails = GenerationStatuses.find(s => s.id === status);
    return statusDetails ? statusDetails.name : 'Неизвестный статус';
}

export const { addVersion } = frameDataVersionsSlice.actions;

// Экспортируем slice целиком
export default frameDataVersionsSlice;
