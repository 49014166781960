import React, {useEffect, useMemo, useState} from 'react';
import PageContainer from '../../components/container/PageContainer';
import TypeSwitchBar from "../../layouts/full/TypeSwitchBar/TypeSwitchBar";
import {IconLayoutDashboard} from "@tabler/icons";
import {
    fileUtilsApiUrl, frameDataApiUrl
} from "../../schema/Environment";
import {Banks, getColorBankByKeyword, setSelectedTypeId} from "./reducers/bankFilterSlice";
import {IconBuildingBank} from "@tabler/icons-react";
import axios from "axios";
import {useSnackbar} from "notistack";
import {AxiosPostFile} from "../../axios/AxiosPost";
import UseToken from "../../auth/UseToken";
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import DashboardCard from "../../components/shared/DashboardCard";




let launchTypeItems = [{
    id: "unionHashFile",
    title: "Объединить файлы с клиентами",
    icon: IconLayoutDashboard,
    url: fileUtilsApiUrl + "/api/v1/campaign_client_file/combine"
}];

function findItemById(items, id) {
    return items.find(item => item.id === id);
}

function FileOperationsView () {

    const [selectedTypeId, setSelectedTypeId] = useState(null);
    const [selectedBank, setSelectedBank] = useState(null);


    const handleSelectBank = (id) => {
        setSelectedBank(id);
    };


    let banksList = Banks.sort((a, b) => a.name.localeCompare(b.name));


    let bankItems = Array.from(banksList, (item) => ({
        id: item.id,
        title: item.name,
        icon: () => <IconBuildingBank stroke={2} color={item.id ? getColorBankByKeyword(item.id) : 'grey'}/>
    }));


    return (
        <PageContainer title="Sample Page" description="this is Sample page">
            <TypeSwitchBar title={'Фильтр. Банк'} items={bankItems} valueSetter={handleSelectBank} selectedId={selectedBank} gridSize={"SMALL"}/>
            <TypeSwitchBar title={'Тип задачи'} items={launchTypeItems} valueSetter={setSelectedTypeId} selectedId={selectedTypeId}/>
            {/*<FileOperationsDataView selectedBank={selectedBank} selectedTypeId={selectedTypeId}/>*/}
            {selectedTypeId === "unionHashFile" ?
                <FileUploadForm bank={selectedBank} selectedTypeId={selectedTypeId}/>
                :<></>
            }

        </PageContainer>
    );
}


const FileUploadForm = ({bank, selectedTypeId}) => {
    // Состояние для хранения файлов и банка
    const {currentToken, setToken} = UseToken();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedFiles, setSelectedFiles] = useState(null);

    let type = findItemById(launchTypeItems, selectedTypeId);

    if(type === undefined){
        return <></>
    }

    // Обработчик для изменения файла
    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files); // получаем массив файлов
    };

    // Обработчик для отправки формы
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedFiles) {
            enqueueSnackbar('Выберите файлы для загрузки', { variant: 'warning' })
            return;
        }
        if (!bank) {
            enqueueSnackbar('Выберите банк', { variant: 'warning' })
            return;
        }

        // Создаем объект FormData
        const formData = new FormData();
        // Добавляем файлы в FormData
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('files', selectedFiles[i]);
        }
        // Добавляем поле bank в FormData
        formData.append('bank', bank);


        try {
            // Отправляем запрос на сервер
            const response = await axios.post(type.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': `${currentToken}`,
                },
                responseType: 'blob', // если ожидается zip-архив в ответе
            });

            enqueueSnackbar('Файл успешно обработан', { variant: 'success' });
            // Обработка ответа (например, загрузка архива)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'files.zip'); // Название файла для скачивания
            document.body.appendChild(link);
            link.click();


        } catch (error) {
            enqueueSnackbar('Ошибка при отправке файлов', { variant: 'error' });
        }
    };

    return (
        <DashboardCard>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={6}>
                    <Typography variant="h6">Выбрано файлов: {selectedFiles ? selectedFiles.length : 0}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Box>
                        <input
                            accept=".txt,.csv,.zip"
                            style={{ display: 'none' }}
                            id="upload-file"
                            type="file"
                            multiple
                            onChange={handleFileChange}
                        />
                        <label htmlFor="upload-file">
                            <Button variant="contained" component="span">
                                Выберите файлы
                            </Button>
                        </label>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={!selectedFiles}
                            style={{ marginLeft: '10px' }}
                        >
                            Загрузить
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </DashboardCard>

        // <form onSubmit={handleSubmit}>
        //     <div>
        //         <label htmlFor="file">Выберите файлы:</label>
        //         <input
        //             type="file"
        //             id="file"
        //             onChange={handleFileChange}
        //             multiple
        //             required
        //         />
        //     </div>
        //     <button type="submit">Отправить</button>
        // </form>
    );
};


export default FileOperationsView;
